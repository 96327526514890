/* Discover CSS */

.Discover {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
  margin-bottom: 20px;
  position: relative;
}
.img-discover {
  width: 100%;
  height: 270px;
  object-fit: cover;
  object-position: top;
}
.discoverInput::placeholder {
  font-size: 1.1rem;
}
.prod-box-discover .img-bg-overlay {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  left: 10px;
  background-color: #000;
  opacity: 0.1;
}
.prod-box-discover {
  position: relative;
  margin-bottom: 10px;
}
.labels-discover {
  width: calc(100% - 30px);
  left: 15px;
  padding: 0;
}
.dropdown-item {
  position: relative;
}
.dropdown-item p {
  font-size: 0.8rem;
  margin-bottom: 0;
}
.icon-right {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
.discover_margin {
  margin-bottom: 20px;
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}
.no-hov {
  color: #000;
  text-align: center;
  cursor: pointer;
}
.no-hov:hover {
  color: #000;
}
.refresh-box {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.btn-refresh {
  border-color: transparent;
}
.btn-refresh:hover,
.btn-refresh:visited,
.btn-refresh:focus {
  background-color: black !important;
  color: white !important;
}
.loader-discover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}

.prod-box-discover .title-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;

  text-align: center;
  text-transform: uppercase;
  display: none;
}
.prod-box-discover a:hover .title-hover {
  display: block;
}
.prod-box-discover a:hover .img-bg-overlay {
  opacity: 0.3;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .no-pd-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .discover_margin {
    margin-bottom: 20px;
    background-color: #000;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .discover .container {
    max-width: 100%;
  }
  .no-pd-refresh {
    padding: 0px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .btn-refresh {
    font-size: 0.9rem;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
  }
  .img-discover {
    height: 110px;
  }
  .search-fixed {
    position: fixed;
    top: 51px;
  }
  .Search {
    padding-top: 59px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 500px) {
  .img-discover {
    height: 140px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 768px) {
  .img-discover {
    height: 178px;
  }
}

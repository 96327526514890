/*Wishlist*/

.Wishlist {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
  margin-bottom: 30px;
  position: relative;
}

.Wishlist .marked-price{
  font-size: .7rem;
}

.card-wishlist {
  width: 100% !important;
  height: 100%;
  border: 1px solid #f5f5f5;
}
.card-wishlist-border {
  margin-bottom: 1.5rem;
}

.center {
  text-align: center;
}

.btn {
  width: 100%;
}

.custom {
  background-color: #000;
  border: 0;
  line-height: 2.2;
  color: #fff !important;
  text-transform: capitalize;
  font-size: 0.9rem;
  transition: all 500ms ease;
  border: 1px solid #000;
  border-radius: 0px;
}

.custom:hover {
  color: #000 !important;
  background-color: #fff;
}

.custom:active {
  color: #e6e6e6 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #e5e5e5 !important;
}

.top-card,
.bottom-card {
  padding: 10px 0;
  width: 100%;
}

.card-body-wishlist {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.no-pd {
  padding-left: 0;
  padding-right: 0;
}
.wish-item-size.error {
  border-color: red;
}
.wish-error-text {
  color: red;
  font-size: 0.8rem;
  text-align: left;
  padding: 5px 0;
  display: none;
}

.no-items-wish {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.no-items-wish p {
  text-align: center;
  font-size: 1.5rem;
}
.no-items-wish i {
  font-size: 2rem;
}
.remove-wish {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 100;
  cursor: pointer;
}
.wish-margin {
  margin-right: 15px;
}
.card-body-wishlist .card-title {
  font-size: 1rem;
  font-family: "Helvetica-Bold";
}
.wish-price {
  font-family: "Helvetica-Bold";
}
.card-body-wishlist .card-text {
  font-size: 0.8rem;
}

.loader-wish {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}

.loader-wish-all-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 52px;
  background-color: rgba(239, 239, 239, 0.3);
}
.title-wish {
  font-size: 1.1rem;
}

.wish-img-cover {
  object-fit: contain;
  object-position: top;
}

.wish-uper{
  text-transform: uppercase;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .Wishlist .no-pd {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .btn-wishlist,
  .wish-item-size {
    font-size: 0.75rem !important;
  }
  .card-body-wishlist {
    padding: 0.6rem;
  }
  .wish-margin {
    margin-right: 0px;
  }
}


/* Achievement CSS */

.achievement_container {
  background-color: #ea7d99;
  border-radius: 10px;
}
.sept-2 {
  margin-top: 2rem;
}
.sept-1 {
  margin-top: 1rem;
}
.achievement_pointer{
  cursor: pointer;
}

#unlockedCode{
  background-color: transparent;
  border: 0;
  border-color: transparent;
  outline: none;
  color: white;
  cursor: pointer;
  width: 200px;
  font-size: .8rem;
}

.achievement_cap{
  text-transform: capitalize;
  display: inline-block;
}
.achievement_trophy_container {
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  max-width: 400px;
  max-height: 400px;
  border-radius: 250px;
  width: 80px;
  height: 80px;
  position: relative;
  display: block;
  margin-left: auto;
}
.achievement_text {
  font-size: 0.8rem;
  color: #fff;
}
.achievement_torphy {
  position: relative;
  top: 22px;
  font-size: 1.6rem;
}

.white {
  color: #fff;
}

.Categories-Page {
  height: auto;
  min-height: 100vh;
  padding-top: 45px;
  width: 100%;
  overflow-x: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .Categories-Page {
    padding-top: 44px;
  }
}

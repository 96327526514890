/* Product Detail */

.ProductDetail {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
  overflow: hidden;
}

#product-size {
  font-size: 1.1rem;
}

.center {
  text-align: center;
}

.discount-save{
  font-size: .85rem;
  color: red;
  display: block;
}

.marked-price{
  font-size: .9rem;
  text-decoration:line-through;
  color: #a2a2a2;
  padding-right: .5rem;
}

.new-price{
  font-size: 1rem;
  color: black;
}

.product_title {
  text-transform: uppercase;
  color: #222;
  font-size: 1.3rem;
  line-height: 1.55;
}

.product_description {
  color: #000;
  font-size: 1rem;
  font-weight: 100;
}

.product_price {
  font-size: 1rem;
  margin-right: 0.6rem;
}

.overlay {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
}
.overlay-dark {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
}

#product-size.error {
  border-color: red;
}
.product-error-text {
  color: red;
  font-size: 0.8rem;
  text-align: left;
  padding: 5px 0;
  display: none;
}
.btn-add-wish i {
  padding-left: 0.5rem;
}

.size-guide-text {
  text-align: right;
}
.size-guide-text a {
  text-decoration: underline !important;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .product_title {
    margin-top: 2.5rem;
    font-size: 1rem;
  }

  .product_description {
    font-size: 0.8rem;
  }

  .product_price {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .ProductDetail .no-pd-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .special-pd-mobile {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  #sizeModal .modal-dialog {
    max-width: 90%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  #sizeModal .modal-dialog {
    width: 80%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  #sizeModal .modal-dialog {
    width: 85%;
    max-width: 100%;
  }
}

.Search {
  height: auto;
  min-height: 100vh;
  padding-top: 64px;
  margin-bottom: 20px;
  position: relative;
}

.visible-search {
  display: block;
}
.hidden-search {
  display: none;
}
.searchSeparator {
  background-color: #ffffff;
}
.search_letter {
  font-size: 1.5rem;
  color: #000;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
}

.box-list-categorys {
  background-color: white;
}
.category-entry {
  font-size: 1rem;
  color: #000;
  text-transform: uppercase;
  display: block;
  padding: 1rem 0 1rem 20px;
  border-top: 1px solid #eeee;
}
.category-entry:last-child {
  border-bottom: 1px solid #eeee;
}
.category-entry p {
  margin-bottom: 0;
}
.search-fixed {
  position: fixed;
  top: 56px;
  z-index: 10;
  background-color: white;
  padding-top: 8px;
}
.all-list {
  padding-top: 38px;
  height: 100%;
}
.maxw-100 {
  max-width: 100%;
}
.no-pd {
  padding-left: 0;
  padding-right: 0;
}
.Search .loader-search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

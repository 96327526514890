@font-face {
  font-family: "Helvetica";
  src: url("../assets/fonts/Helvetica.ttf");
  font-display: auto;
}

@font-face {
  font-family: "Didot";
  src: url("../assets/fonts/DidotRegular.ttf");
  font-display: auto;
}

html,
body,
#root {
  /* overflow-x: hidden; */
  width: 100%;
  height: 100%;
  font-family: "Adobe Garamond W08", "adobe-garamond-pro",
    "AGaramondPro-Regular", "Times New Roman", "Times", "serif";
}

/* Fonts */
.closit_title {
  font-family: "Adobe Garamond W08','adobe-garamond-pro','AGaramondPro-Regular','Times New Roman','Times','serif";
}

.closit_text {
  font-family: "Adobe Garamond W08','adobe-garamond-pro','AGaramondPro-Regular','Times New Roman','Times','serif";
  font-weight: 600;
}

* {
  font-family: "Adobe Garamond W08','adobe-garamond-pro','AGaramondPro-Regular','Times New Roman','Times','serif";
}
.card-img-top {
  min-height: 24vw;
}
/**********/

.notify .notify-text {
  color: white !important;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff !important;
  background-color: #000;
}

/* Inputs */
input::placeholder {
  overflow: visible;
}
.location-form-input {
  overflow: visible !important;
  font-size: 15px;
}

/**********/

.no_hover {
  color: #000 !important;
}
.btn-primary:hover {
  border-color: black;
}
.loader-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 52px;
  background-color: rgba(239, 239, 239, 0.3);
  z-index: 9999;
}
.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.wishlist-box {
  position: absolute;
  right: 8px;
  top: 5px;
  z-index: 99;
  cursor: pointer;
}
.wishlist-box i:hover {
  color: rgba(0, 0, 0, 0.5);
}
/* For sale laber in Home */
.forSale-box {
  position: absolute;
  left: -22px;
  top: -5px;
  z-index: 99;
  background: #000;
  color: #fff;
  border-radius: 50%;
  padding: 16px 5px 0px 5px;
  font-size: 10px;
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  .forSale-box {
    left: 0px;
    top: -15px;
  }
}
/* For sale laber in Similars */
.forSaleSimilars-box {
  position: absolute;
  left: -7px;
  top: -12px;
  z-index: 99;
  background: #000;
  color: #fff;
  border-radius: 50%;
  padding: 16px 5px 16px 5px;
  font-size: 10px;
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  .forSaleSimilars-box {
    left: -9px;
    top: -4px;
    padding: 15px 4px 15px 4px;
  }
}
/* For sale laber in Category Details */
.forSaleCategoryDet-box {
  position: absolute;
  left: -16px;
  top: -22px;
  z-index: 99;
  background: #000;
  color: #fff;
  border-radius: 50%;
  padding: 16px 5px 0px 5px;
  font-size: 10px;
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  .forSaleCategoryDet-box {
    left: 0px;
    top: -15px;
  }
}

.trash-cards i {
  cursor: pointer;
}
.pars-str,
.pars-str * {
  font-family: "Adobe Garamond W08','adobe-garamond-pro','AGaramondPro-Regular','Times New Roman','Times','serif" !important;
}
.visible {
  display: block !important;
}
a {
  cursor: pointer;
  color: black;
}
a:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.separator-section {
  margin: 30px 0;
}
.layout {
  position: relative;
  display: block;
}
.blockScroll {
  position: fixed;
  width: 100% !important;
}
*:focus,
*:visited,
* {
  outline: none !important;
  outline-color: transparent !important;
  box-shadow: none;
}
*:focus,
*:visited {
  box-shadow: none !important;
}
.form-control:focus {
  border-color: inherit;
}
.form-control {
  border-radius: 0px;
}
.col-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
.col-15 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 576px) {
  .col-sm-15 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 768px) {
  .col-md-15 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-lg-15 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-xl-15 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.btn {
  transition: all 500ms ease;
}

.btn-secondary:hover {
  color: black !important;
  background-color: white !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: black !important;
}
.nav-item > NavLink:hover {
  color: #000 !important;
  text-decoration: none;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .no-pd-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

/*Change styles the credit card form DONT TOUCH*/
.rccs__chip {
  display: none;
}
.rccs__issuer {
  left: 20px !important;
  right: 0 !important;
  background-image: url("../assets/logo.gif") !important;
  visibility: visible !important;
  display: block !important;
  background-position: top right !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  width: 35% !important;
}
.rccs__card__background,
.rccs__card--front,
.rccs__card--back {
  background-color: #000 !important;
  background: #000 !important;
  box-shadow: none !important;
}
.rccs__number,
.rccs__name,
.rccs__expiry__valid,
.rccs__expiry__value {
  color: black !important;
}
.rccs__card--back .rccs__issuer {
  left: 50% !important;
}
/*REALLY DONT TOUCH, DO NOT YOU SEE THIS !! */

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: black !important;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: black !important;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: black !important;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: black !important;
}
.btn-secondary:disabled:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.new-style {
  color: black;
}
.star-modal {
  background-color: #ffffff;
}
body {
  padding: 0 !important;
}
.lot_bid_container .btn-secondary.disabled,
.lot_bid_container .btn-secondary:disabled {
  padding: 0;
}

.Toastify__toast--info {
  color: #ffffff;
  background-color: #000000 !important;
  box-shadow: 0px 0px 0px 0px black !important;
  z-index: 9999;
}
.notify .notify-title {
  color: #fff;
  text-align: center;
  font-size: 1rem;
}
.notify .notify-description {
  margin-bottom: 0rem !important;
  color: black;
  font-size: 0.8rem;
}
.notify {
  border: 1px solid white;
}
.notify .notify-text {
  margin-bottom: 0 !important;
  color: black;
}
@media only screen and (min-width: 1800px) {
  .container-fluid {
    max-width: 1600px;
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  color: rgb(250, 223, 223) !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .special_height {
    /*EN PROD*/
    height: 180px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .special_height {
    /*EN PROD*/
    height: 240px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .special_height {
    /*EN PROD*/
    height: 240px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 992px) {
  .special_height {
    /*EN PROD*/
    height: 280px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .wish-img-cover {
    /*EN PROD*/
    height: 180px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .wish-img-cover {
    /*EN PROD*/
    height: 240px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .wish-img-cover {
    /*EN PROD*/
    height: 240px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 992px) {
  .wish-img-cover {
    /*EN PROD*/
    height: 280px;
    overflow: hidden;
  }
}

.elcorchete {
  font-size: 14px;
}

.informationTitle {
  font-size: 18;
  font-family: "HelveticaNeue-Bold";
  font-weight: "bold";
  color: #3f4549;
  text-align: left;
}

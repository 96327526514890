/* Detail Lot */

.DetailLotPage {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 60px;
}
.DetailLotPage #carousel-Prod-Detail .carousel-indicators {
  bottom: -40px;
}
.lot-detail-description * {
  font-size: 0.8rem;
  margin-bottom: 8px;
}
.lot-detail-description {
  margin-bottom: 20px;
}
.lot_icon_container a:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
.lot_detail_title {
  font-size: 1rem;
  text-transform: capitalize;
}

.lot_text {
  font-size: 0.8rem;
}

.auction_separator {
  border-bottom: 0.3px solid #e5e5e5;
}

.center {
  text-align: center;
}

.lot_title {
  text-transform: uppercase;
  color: #222;
  font-size: 1.3rem;
  line-height: 1.55;
  text-align: center;
}

/* .lot_title span {
  font-size: 1rem;
  font-weight: normal;
} */

.lot-description {
  color: #000;
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
}

.bit-amount {
  color: #000;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: right;
}

.no-pd {
  padding-left: 0;
  padding-right: 0;
}
/* Temporizador*/

.clockdiv-linear {
  display: flex;
  margin-bottom: 10px;
}

.clockdiv-linear > div {
  padding: 0 10px;
  border-radius: 3px;
  background: white;
  display: inline-block;
  width: 60px;
}

.clockdiv-linear div > span {
  border-radius: 3px;
  background: black;
  display: inline-block;
  color: #fff;
  font-weight: 100;
  text-align: center;
  font-size: 24px;
  width: 100%;
}

.smalltext {
  padding-top: 5px;
  font-size: 14px;
  color: black;
  font-weight: 100;
  text-align: center;
}

.align-center {
  align-items: center;
}

#lot-bid.error {
  border-color: red;
}

.lot-error-text {
  color: red;
  font-size: 0.8rem;
  text-align: left;
  padding: 5px 0;
  display: none;
}

.btn-lot-detail {
  height: 38px;
}

.lot_modal {
  background-color: #fff;
  border-radius: 6px;
}

.modal_item {
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
}

.modal_item:hover {
  color: #fff;
}

.lot_modal_x {
  font-size: 1.8rem !important;
}

.main_lot_img {
  width: 100%;
  height: 560px;
  object-fit: contain;
  object-position: top;
}

.header-lots .lot_detail_title {
  text-align: center;
  text-transform: uppercase;
}
.header-lots .clockdiv-linear {
  justify-content: center;
}

.DetailLotPage .auction_separator {
  margin-bottom: 1rem;
  margin-top: 0;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .header-lots .lot_detail_title {
    text-align: center;
  }
  .header-lots .clockdiv-linear {
    justify-content: center;
  }
}

/* History CSS */

.History {
  height: auto;
  min-height: 100vh;
  padding-top: 52px;
  background-color: #efefef;
}

.angle-size {
  font-size: 1.4rem;
}

.btn-back-order {
  display: block;
  max-width: 285px;
  margin: 0 auto 1rem;
}

.item-card-cart {
  box-shadow: 0 0 0px 0px black;
}

.order_link:hover {
  color: black;
}
.positioned {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.title-history {
  font-size: 1.1rem;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}
.subtitle-history {
  font-size: 1rem;
}

.no-orders {
  font-size: 1rem;
  text-align: center;
}
.loader-history {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}

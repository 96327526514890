/*Auctions*/

.Auctions {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-top: 50px;
}
.aution_bot_margin {
  margin-bottom: 30px;
}
.auctions_header {
  font-size: 1.1rem;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.inside_header_auction {
  position: absolute;
  color: #fff;
  margin-bottom: 0px;
}
.inside_footer_auction {
  position: absolute;
  color: #fff;
  margin-bottom: 0px;
  bottom: 1px;
}
.auction_separator {
  border-bottom: 0.1px solid #000000 !important;
}

.auction_img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: top;
}

.auction_bg_container {
  position: relative;
  margin-top: 10px;
}

.auctioner_subcontainer {
  margin-top: 30px;
  margin-bottom: 40px;
}

.auctioner_name {
  font-size: 25px;
  line-height: 1.4;
  margin: 0;
  text-transform: capitalize;
}

.auctioner_close {
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
}

.auction_btn {
  width: 100%;
  text-align: center;
  background-color: #000;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0px;
  padding: 15px 30px;
  transition: all 500ms ease;
  font-size: 0.8rem;
}

.auction_btn:hover {
  color: black;
  background-color: white;
}

.auction_btn:active {
  background-color: black !important;
}

.auction_btn:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

.auction_btn:not(:disabled):not(.disabled).active:focus,
.auction_btn:not(:disabled):not(.disabled):active:focus,
.show > .auction_btn.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

/* Right Col */

.upcoming_img {
  width: 70px;
}

/* Temporizador*/

.clockdiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clockdiv > div {
  padding: 15px 10px;
  border-radius: 3px;
  background: white;
  display: inline-block;
  border: 1px solid black;
  margin: 0 15px;
  width: 80px;
}
.clockdiv div:nth-child(1) {
  margin-left: 0;
}
.clockdiv div > span {
  border-radius: 3px;
  background: black;
  display: inline-block;
  color: #fff;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  width: 100%;
}

.smalltext {
  padding-top: 5px;
  font-size: 16px;
  color: black;
  font-weight: 100;
  text-align: center;
}

/* transparent countdown*/
.clockdiv-transparent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.clockdiv-transparent > div {
  padding: 15px 10px;
  display: inline-block;
  margin: 0 15px;
  width: 80px;
}
.clockdiv-transparent div:nth-child(1) {
  margin-left: 0;
}
.clockdiv-transparent div > span {
  display: inline-block;
  color: #fff;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  width: 100%;
}

.clockdiv-transparent .smalltext {
  padding-top: 5px;
  font-size: 16px;
  color: white;
  font-weight: 100;
  text-align: center;
}

.auctioner_name {
  font-size: 1rem;
}
.auctioner_close {
  font-size: 0.8rem;
}

.no-auctions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.no-auctions p {
  text-align: center;
  font-size: 1.5rem;
}
.no-auctions i {
  font-size: 2rem;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .clockdiv-transparent > div {
    margin: 0;
  }
  .auction_btn {
    margin-top: 15px;
    font-size: 1rem;
  }
  .auctioner_subcontainer {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .Auctions .clockdiv-transparent {
    display: none !important;
  }
  .auction_img {
    height: 200px;
  }
  .auctioner_name {
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .auction_btn {
    padding: 15px 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .auction-web {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .auction-mobile {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .no-auctions {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}

@media only screen and (min-width: 375px) and (max-width: 414px) {
  .no-auctions {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}

.ConfirmLot {
  height: auto;
  min-height: 100vh;
  padding-top: 53px;
  position: relative;
}
.separator-confirm {
  width: 100%;
  border-bottom: 0.3px solid #e5e5e5;
  margin-top: 0;
}
.title-confirm {
  text-align: center;
  font-size: 1.5rem;
}
.item-text-confirm {
  text-align: center;
  font-size: 1rem;
}
.confirm-box {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.btn-confirm {
  max-width: 320px;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
}
/* Temporizador*/

.clockdiv-linear.confirm {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.clockdiv-linear > div {
  padding: 0 10px;
  border-radius: 3px;
  background: white;
  display: inline-block;
  width: 70px;
}
.clockdiv-linear div > span {
  border-radius: 3px;
  background: black;
  display: inline-block;
  color: #fff;
  font-weight: 100;
  text-align: center;
  font-size: 24px;
  width: 100%;
}

.smalltext {
  padding-top: 5px;
  font-size: 14px;
  color: black;
  font-weight: 100;
  text-align: center;
}

.item-name {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline !important;
}

.CountDown-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .ConfirmLot .no-pd-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

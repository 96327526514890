.card-text img {
  width: 100% !important;
  height: 150px !important;
  object-fit: cover;
  object-position: center;
}
.journal_new_title {
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
}
.upper {
  text-transform: uppercase;
  font-size: 0.9rem;
}
.journal_new_text {
  text-align: center;
  color: #ffffff;
}
.latest_title {
  font-size: 2rem;
  text-align: center;
}
.latest_border {
  border-bottom: 1px solid pink;
}
.journal-card {
  border: 0;
  border-radius: 0;
}
.journal-card .card-body {
  padding: 10px 0;
}
.journal-card img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 400px;
}
.journal-card-img-box {
  position: relative;
  width: 100%;
}
.journal-card-img-box:hover .overlay {
  opacity: 0.3;
  cursor: pointer;
}
.journal-card-img-box .overlay {
  opacity: 0;
  transition: all 400ms linear;
}
.separation-journal {
  margin-bottom: 30px;
}
.card-date {
  font-size: 15px;
  line-height: 1.5;
  color: #999;
}
.journal-ind-card-title {
  text-transform: capitalize;
}
.journal-ind-card-title {
  font-size: 1rem;
}
.loader-journal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}

.new_bg {
  background-color: #000000;
}
.no_bg {
  background-color: transparent !important;
  color: #ffffff !important;
  padding: 20px;
}
.hover {
  text-decoration: none;
  position: relative;
  color: #000;
}
.hover:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  border-bottom: 2px solid #000 !important;
  transition: 0.4s;
}
.hover:hover:after {
  width: 100%;
}
.journal_new_img {
  width: 34%;
}
.hover.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  border-bottom: 2px solid #ffffff !important;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
}
/*#pills-tabContent{
  padding-top: 1rem;
}*/
@media only screen and (min-width: 768px) {
  .centered-journal {
    margin-left: auto;
    margin-right: auto;
  }
  .journal-text-sep {
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .container-journal {
    max-width: 100%;
  }
  .journal-card .card-body {
    padding: 10px 19px;
  }
  .journal_new_img {
    width: 100%;
  }
}

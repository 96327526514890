/*Slider Nav*/

.SliderNav {
  margin-top: 30px;
}

/* .product_description * {
  font-family: "Helvetica" !important;
} */
.detail_title {
  font-size: 14px;
}

.title_collapse {
  font-size: 1rem;
  text-align: left;
}

.detail_img {
  width: 70%;
}

.collapse_icon {
  float: right;
}
.btn-size-guide {
  width: 290px;
  display: block;
  max-width: 100%;
  color: white !important;
}
@media (max-width: 991px) {
  .hide_nav {
    display: none;
  }
  .img_hide {
    display: none;
  }
}

@media (min-width: 992px) {
  .hide_collapse {
    display: none;
  }
  .img_hide {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .btn-size-guide {
    width: 290px;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
}

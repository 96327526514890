.NewAddress {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
  position: relative;
}
.title-new-add {
  font-size: 1rem;
  text-transform: capitalize;
}
.location-form-input {
  width: 100%;
  margin-bottom: 20px;
}
.btn-new-add-map {
  width: 100%;
}
.location-form-input,
.btn-new-add-map {
  height: 38px;
}
#completeManual .modal-input {
  margin-bottom: 1rem;
}
#completeManual .modal-title {
  padding-bottom: 0.5rem;
}
.address-error-text {
  font-size: 1rem;
  color: red;
}

/*Shop*/

.Shop {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
  position: relative;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .col-shop-item-card {
    margin-bottom: 4px !important;
    max-height: 300px;
    min-height: 300px;
  }
}

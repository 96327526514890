.SearchDetails {
  height: auto;
  min-height: 100vh;
  padding-top: 52px;
  position: relative;
}
.card-search-detail {
  border: 0;
}
.card-search-detail-img {
  width: 100%;
}
.card-category-title {
  text-transform: capitalize;
}
.card-search-detail-title {
  font-size: 1rem;
}
.card-search-detail-text {
  font-size: 0.8rem;
}
.card-search-detail-price {
  font-size: 0.8rem;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}
#carousel-Prod-Detail {
    z-index: 1;
}
#carousel-Prod-Detail .carousel-indicators {
    bottom: -30px;
}
#carousel-Prod-Detail .carousel-indicators .active {
    background-color: black !important;
}
#carousel-Prod-Detail .carousel-indicators li {
    background-color: rgba(128, 128, 128, 0.7);
}
#carousel-Prod-Detail .carousel-indicators li {
    width: 7px;
    height: 7px;
    border-radius: 100%;
}
  
#carousel-Prod-Detail {
    height: 640px;
}
.img-cover {
    object-fit: contain;
    object-position: top;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
  color: black;
}
.carousel-control-prev-icon i,
.carousel-control-next-icon i{
  font-size: 2.5rem;
}

.carousel-control-next-icon:after {
  /*content: ">";
  font-size: 40px;
  color: #000;*/
}

.carousel-control-prev-icon:after {
  /*content: "<";
  font-size: 40px;
  color: #000;*/
}

#carousel-Prod-Detail .carousel-control-prev,
#carousel-Prod-Detail .carousel-control-next {
  display: flex;
  opacity: 0;
  transition: all 600ms ease;
}

#carousel-Prod-Detail:hover .carousel-control-prev,
#carousel-Prod-Detail:hover .carousel-control-next {
  opacity: 1;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
    #carousel-Prod-Detail {
      height: 440px;
    }
}
.NewCard {
  height: auto;
  min-height: 100vh;
  padding-top: 63px;
}
.btn-new-card {
  max-width: 375px;
  display: block;
  margin: 0 auto;
  padding: 0.7rem;
}
.credit-box {
  margin-top: 15px;
}
.title-new-card {
  font-size: 1rem;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .btn-new-card {
    margin-bottom: 1rem;
  }
}

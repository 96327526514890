.HistoryDetail {
  height: auto;
  min-height: 100vh;
  padding-top: 63px;
  background-color: #efefef;
}

.HistoryDetail .marked-price{
  font-size: .7rem;
}

.title-order {
  font-size: 1rem;
  text-transform: capitalize;
}

.mb-20{
  margin-bottom: 20px;
}

.item-order-title{
  font-size: 1rem;
  text-transform: uppercase;
}
.item-order-price {
  font-size: 0.8rem;
}

.order-number-title {
  font-size: 1rem;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}
.order-card {
  box-shadow: 0 0 0px 0px black;
  border: 0;
  border-radius: 2px;
  margin-bottom: 20px;
}

.order-card.h-100 {
  margin-bottom: 0;
}

.order-card p {
  line-height: 1.2;
}
.label-discount {
  font-size: 1.2rem;
  color: #000;
  font-weight: 900;
}
.order-card .text-discount {
  font-size: 0.8rem;
  color: black;
  text-align: left;
  margin-top: 0;
  line-height: 1;
}

.title-order-final {
  font-size: 1rem;
}
.icon-check i {
  font-size: 2rem;
}
.HistoryDetail .second-col * {
  text-align: right;
  margin-bottom: 0.5rem;
}
.HistoryDetail .first-col * {
  text-align: left;
  margin-bottom: 0.5rem;
}
.order-text {
  font-size: 0.8rem;
}
.btn-back-order {
  display: block;
  max-width: 285px;
  margin: 0 auto 1rem;
}
.img-order-expanded {
  width: 100%;
  height: 240px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  display: block;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .order-card-body {
    padding: 1rem 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .HistoryDetail .no-pd-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}
.Checkout {
  height: auto;
  min-height: 100vh;
  padding-top: 70px;
  background-color: #efefef;
  padding-bottom: 30px;
  position: relative;
}
.separator-row {
  margin-bottom: 13px;
}
.no-m {
  margin-bottom: 0;
}
.box-info-check p {
  text-align: center;
  font-size: 1rem;
}
.box-info-check,
.box-info-order-check {
  box-shadow: 0 0 0px 0px black;
}
.box-info-check {
  align-items: center;
  padding: 40px 0;
}
.title-check {
  text-transform: uppercase;
}
.box-info-check p {
  color: black;
}
.box-info-check .select-check-text {
  color: red;
}

.box-info-order-check {
  align-items: center;
  padding: 25px 0px;
}
.box-info-order-check p {
  margin-bottom: 0px;
}
.right p {
  text-align: right;
}
.btn-check-place {
  margin-top: 1rem;
}
.promo-input {
  margin: auto;
  text-align: left;
}

.text-l {
  text-align: left !important;
}

.text-icons {
  display: flex;
  justify-content: space-evenly;
}
.check-green {
  font-size: 1rem;
  color: green;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .box-info-check {
    padding: 25px 0;
  }
  .box-info-check p {
    text-align: center;
    font-size: 0.9rem;
  }
  .box-info-check div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  font-size: 1rem;
  padding: 0.7rem;
}
.loader-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 52px;
  background-color: rgba(239, 239, 239, 0.3);
}
.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.label-discount {
  font-size: 1.2rem;
  color: #000;
  font-weight: 900;
}
.text-discount {
  font-size: 0.8rem;
  color: black;
  text-align: left;
  margin-top: 1rem;
}


.check-sw .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.check-sw .switch input {
  display: none;
}

.check-sw .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.check-sw .slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .check-sw .slider {
  background-color: black;
}

input:focus + .check-sw .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .check-sw .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.check-sw .slider.round {
  border-radius: 34px;
}

.check-sw .slider.round:before {
  border-radius: 50%;
}

.check-sw{
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-sw{
  font-size: .7rem;
  padding: 0 10px;
  height: 25px;
}
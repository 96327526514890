.share_modal {
  background-color: #ffffff;
  border-radius: 6px;
}
#shareModal {
  padding-right: 0 !important;
}

.share_modal_x {
  font-size: 1.8rem !important;
}
.share_modal_item {
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
}

.share_modal_item:hover {
  color: rgba(0, 0, 0, 0.5);
}

#shareModalLabel {
  margin-bottom: 0;
}

#shareLink {
  color: #000;
  border-color: rgb(229, 229, 229) !important;
  font-size: 0.8rem;
  background-color: #f7f7f7;
}

/* Rewards CSS */

.Rewards {
  /*height: auto;
  min-height: 100vh;
  overflow: hidden;*/
}
.reward_name_title {
  font-size: 1.3rem;
}
.share_item {
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
}
.share_item:hover {
  color: #fff;
}
.reward_pic_container {
  text-align: center;
}
.btn-add-wish i {
  padding-left: 0.5rem;
}
.share_modal {
  background-color: #ffffff;
  border-radius: 6px;
}
.reward_pic {
  background-color: #ffffff;
  cursor: pointer;
  max-width: 400px;
  max-height: 400px;
  border-radius: 250px;
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
}
.sept-3 {
  margin-top: 3rem;
}
.sept-2 {
  margin-top: 2rem;
}
.reward_name_container h3 {
  text-transform: capitalize;
}
.reward_name_container {
  text-align: center;
}

.trophy {
  font-size: 2rem;
}

.title-trophy {
  margin-top: 1.5rem;
}
.trophy_text {
  font-size: 0.7rem;
}

.w-300 {
  width: 300px;
}

.share-reward {
  background-color: #000;
  color: #fff !important;
  border: 1px solid #000;
  line-height: 2.2;
  text-transform: capitalize;
  border-radius: 0px;
  font-size: 0.9rem;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border-color: black !important;
}

.share-reward:hover,
.share-reward:active {
  color: #000 !important;
  background-color: #fff !important;
  border-color: black !important;
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .header-settings {
    max-width: 100%;
  }
}

.NotificationSlider {
  padding-top: 50px;
  background-color: #000000;
  padding-right: 0px;
  text-align: center;
}

.notification_title {
  font-size: 0.8rem;
  text-align: center;
}

.NotificationSlider p {
  margin-top: 0;
  margin-bottom: 0;
  color: #ffffff;
}

@media (max-width: 375px) {
  .special_pad {
    padding-right: 0px !important;
  }
}

@media (max-width: 320px) {
  .special-width {
    width: 89% !important;
    flex: 0 0 89.666667% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .notification_title {
    font-size: 0.8rem;
  }
  .NotificationSlider {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
  .NotificationSlider {
    padding-top: 60px;
  }
}

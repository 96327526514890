.JournalCardMini {
  max-width: 100%;
}

.card-text img {
  width: 100% !important;
  height: 150px !important;
  object-fit: cover;
  object-position: center;
}
.journal-card-mini {
  border: 0;
  border-radius: 0;
}
.journal-card-mini .card-body {
  padding: 10px 0;
}
.journal-card-mini img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 300px;
}
.journal-card-mini-img-box {
  position: relative;
  width: 100%;
  height: 310px;
}
.journal-card-mini-img-box:hover .overlay {
  opacity: 0.5;
  cursor: pointer;
}
.journal-card-mini-img-box .overlay {
  opacity: 0;
  transition: all 400ms linear;
}
.journal-card-mini .card-title {
  text-transform: capitalize;
}

.journal-card-mini .card-title {
  font-size: 1rem;
}

.journal-hr {
  width: 100%;
  border-bottom: 0.1px solid #eaeaea !important;
  margin-top: 0;
}
.body-journal-card-mini {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.body-journal-card-mini .card-title {
  text-align: center;
  color: white;
  width: 100%;
  margin-bottom: 0;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .body-journal-card-mini {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .body-journal-card-mini .card-title {
    text-align: center;
    color: white;
    width: 100%;
  }
}

.journal_margin {
  margin-top: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .journal-hr {
    width: 100%;
    border-bottom: 0.3px solid #e5e5e5;
    margin-top: 29px;
    position: absolute;
    z-index: 1;
    left: 0;
  }
}
@media only screen and (min-width: 992px) {
  .pd-r-none {
    padding-right: 0;
  }
  .pd-l-none {
    padding-left: 0;
  }
}

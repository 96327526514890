.Footer {
  -webkit-font-smoothing: antialiased;
  width: 100%;
  margin: auto;
  overflow: hidden;
  background-color: #000000;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0;
}
.Footer a:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
.Footer p {
  margin-top: 0;
  margin-bottom: 0rem;
  color: #ffffff;
}
.logoFooter {
  width: 100px;
}
.footer_container {
  margin-bottom: 20px;
  margin-top: 20px;
}
.footer_separator {
  border-top: 1px solid #000;
  width: 100%;
  margin: 0 auto;
}
.footer_separator2 {
  border-top: 1px solid #000;
  width: 100%;
  margin: 20px auto;
}

.footer_title {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 600;
}

.footer_text {
  display: block;
  width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.8rem;
}

.second_row {
  margin-bottom: 10px;
}

.social_container {
  width: auto;
  z-index: 2;
}

.line {
  line-height: 2;
}
.logo-footer {
  display: block;
}
.social_container a {
  display: block;
  text-align: center;
}
.final_container p {
  text-align: center;
}
.social_container a:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
@media (max-width: 575.98px) {
  .logo-footer {
    text-align: center;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .logo-footer {
    text-align: center;
  }
}

@media (max-width: 992px) and (min-width: 320px) {
  .footer_separator {
    width: 100%;
    border-bottom: 0.3px solid #000;
    margin: 0px auto;
    position: absolute;
    z-index: 1;
    left: 0;
  }
  /* .black_separator {
    width: 100%;
    border-bottom: 0.3px solid #000;
    margin: 0px auto;
    position: absolute;
    z-index: 1;
    left: 0;
  } */
  .logo-footer {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .Footer p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Footer {
    width: 100%;
    background-color: #000;
  }
}

.Delivery {
  height: auto;
  min-height: 100vh;
  padding-top: 58px;
  background-color: #efefef;
}
.btn-delivery-back {
  margin-bottom: 1rem;
}
.title-delivery {
  font-size: 1rem;
  text-transform: capitalize;
}
.intro-text-delivery {
  font-size: 0.8rem;
}
.name-product-delivery {
  font-size: 1rem;
  text-transform: capitalize;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.delivery-card {
  border: 0;
  border-radius: 2px;
  margin-bottom: 20px;
}
.delivery-card p {
  line-height: 1.2;
}
.delivery-card-body .switch {
  margin-left: auto;
  display: block;
  margin-bottom: 1rem;
}
.service-tag span {
  background-color: #efefef;
}
.delivery-card-body .service-tag {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

.delivery-card-body .info-tags span {
  background-color: #efefef;
}
.delivery-card-body .info-tags {
  text-align: right;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}
.second-col-delivery {
  display: flex;
  flex-direction: column;
}
.second-col-delivery .bottom-delivery {
  margin-top: auto;
}
.first-col-delivery {
  display: flex;
  flex-direction: column;
}
.first-col-delivery .bottom-delivery {
  margin-top: auto;
}
.bg-white-intro-delivery {
  background-color: white;
}
.loader-delivery {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .delivery-card-body {
    padding: 1rem 0;
  }
  .Delivery .second-col-delivery,
  .Delivery .first-col-delivery {
    min-height: 180px;
  }
}

.title-editor-card {
  letter-spacing: 0.1rem;
  font-size: 2rem;
  color: #fff !important;
  text-align: center;
}
.text-editor-card {
  color: #fff !important;
  text-align: center;
}
.btn-editor-card {
  font-size: 14px;
  line-height: 16px;
  color: #fff !important;
  text-align: center;
}
.overlay {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
}
.carousel-editor-card {
  height: 400px;
}
.carousel-editor-card .carousel-inner,
.carousel-editor-card .carousel-item {
  height: 100%;
}
.carousel-editor-card img {
  object-fit: cover;
  object-position: top;
  height: 100%;
}
.carousel-editor-card .carousel-caption a,
.carousel-editor-card .carousel-caption p,
.carousel-editor-card .carousel-caption h5 {
  cursor: pointer;
}
.carousel-editor-card .carousel-caption .btn-editor-card:hover {
  text-decoration: underline white;
}
.title-editor-card a,
.text-editor-card a {
  color: white;
}
.title-editor-card,
.text-editor-card {
  text-transform: capitalize;
}
.text-editor-card {
  font-size: 1.2rem;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .carousel-editor-card {
    height: 300px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .carousel-editor-card .carousel-caption {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .title-editor-card {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .text-editor-card {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1800px) {
  .EditorCards .container-fluid {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

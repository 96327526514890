.home-shop {
  margin-top: 55px;
  margin-bottom: 55px;
  text-align: center;
  position: relative;
  min-height: 400px;
  overflow: hidden;
}

.home-shop-image{
  width: 50%;
}

.box-midle-line {
  position: relative;
  font-size: 30px;
  margin-bottom: 30px;
  width: 180px;
  margin: 0 auto;
  background-color: #fff;
  color: #000;
}
.midle-line {
  margin-top: -22px;
  border-color: #000000;
  margin-bottom: 50px;
}
.pdr-0 {
  padding-right: 0;
}
.pdl-0 {
  padding-left: 0;
}
.box-home-shop {
  width: 100%;
  height: 400px;
}
.loader-home-shop-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loader-home-shop-area .loader-home-shop {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .home-shop {
    display: none;
  }
}

.flip-card{
    width: 100%;
    max-width: 290px;
    height: 190px;
    margin: 0px auto;
    position: relative;
}
.flip-card-inner{
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.flip-card-front{
    background:  #c5c5c5 !important;
    z-index: 2;
    border-radius: 14.5px;
}
.avatar {
    position: absolute;
    width: 100px !important;
    top: 11%;
    left: 8%;
}
.flip-card-back{
    background: #c5c5c5 !important;
    z-index: 1;
    transform: rotateY(180deg);
    border-radius: 14.5px;
}
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    
}
.flip-card.hovered .flip-card-inner {
    transform: rotateY(180deg);
  }

.black-stripe {
    background-color: rgb(42, 29, 22);
    height: 22%;
    left: 0px;
    position: absolute;
    top: 9%;
    width: 100%;
}
.signature {
    height: 18%;
    left: 5%;
    position: absolute;
    top: 38%;
    width: 75%;
    background: repeating-linear-gradient(0.1deg, rgb(255, 255, 255) 20%, rgb(255, 255, 255) 40%, rgb(255, 238, 170) 0px, rgb(255, 238, 170) 44%, rgb(255, 255, 255) 0px);
}
.back-logo{
    width: 100px !important;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.5;
    color: #00000080
}

.flip-card .cvc{
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
}
.flip-card .card-number{
    position: absolute;
    top: 48%;
    left: 8%;
    width: 200px;
}
.flip-card .card-name{
    font-family: Courier, monospace;
    text-overflow: ellipsis;
    text-transform: uppercase;
    width: 60%;
    overflow: hidden;
    background-color: transparent;
    border: none;
    position: absolute;
    bottom: 10%;
    left: 8%;
    color: black;
    font-weight: 600;
}
.flip-card .card-name ::placeholder {
    color: '#00000080';
    font-weight: 600;
}
.flip-card .card-date{
    position: absolute;
    right: 8%;
    bottom: 13%;
    width: 55px;
}
.card-date label{
    font-size: 8px;
    font-weight: bold;
    margin-bottom: 0;
}

.flip{
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.flip i{
    cursor: pointer;
}
.error-card{
    font-size: 0.8rem;
    color: red;
    text-align: center;
}


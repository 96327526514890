/* Sort CSS */

.sort-modal {
  background-color: #ffffff;
}

.modal-content {
  border-radius: 0rem;
}
.sort_btn {
  width: 100%;
  border-color: #000000;
  text-align: left;
  color: #ffffff;
  height: 40px;
  padding: 0rem 1.2rem;
  background-color: #000000;
}

.sort_btn:focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
  -webkit-box-shadow: 0 0 0 0.2rem #000000;
  box-shadow: 0 0 0 0.2rem #000000;
}

.sort_btn:active {
  background-color: #fff !important;
  border-color: #000 !important;
}
.sort_btn:hover {
  border-color: #000 !important;
}
.hoverable {
  cursor: pointer;
}
.sort_x {
  position: absolute;
  top: 4px;
  left: -3px;
  font-size: 33px;
  z-index: 1;
  font-weight: 100;
}

.sort_title {
  font-size: 1.5rem;
  text-align: left;
}

.sort_text {
  font-size: 1rem;
  text-align: left;
}

.sort-w {
  width: 92%;
  margin: auto;
  border-bottom: 0.5px solid #000;
}

.filter-w {
  width: 92%;
  margin: auto;
}

.filter-border {
  border-bottom: 0.5px solid #000;
  margin-bottom: 20px;
}

.text-l {
  text-align: left;
}

.list li {
  position: relative;
}

.clear-filters {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: transparent;
}

.radio-list {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.list {
  text-decoration: none;
  list-style-type: none;
  padding: 0;
}
.range-price input {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  margin-bottom: 1.2rem;
  width: 100%;
  height: 38px;
}

/*Journal*/

.Journal {
  height: auto;
  min-height: 100vh;
  padding-top: 50px;
  overflow: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
}

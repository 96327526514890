/*Home*/

.Not-found {
  height: auto;
  min-height: 60vh;
  padding-top: 70px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
}

.bg-404{
  position: absolute;
  height: 100%;
  width: 100%;
  /*top: 50%;
  left: 50%;*/
  /*transform: translate(-50%,-50%);*/
  color: rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text-box-404{
  text-align: center;
}
.btn-404{
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.title-404{
  font-size: 37px;
  line-height: 1.2;
}
.text-404{
  font-size: 20px;
  line-height: 1.2;
}
.btn-404 a{
  color: white;
}

@media only screen and (min-width:320px){
  .bg-404{
    font-size: 120px;
  }
}
@media only screen and (min-width:540px){
 .bg-404{
    font-size: 180px; 
  }
}
@media only screen and (min-width:768px){
  .bg-404{
    font-size: 260px;
  }
}
@media only screen and (min-width:992px){
  .bg-404{
    font-size: 340px;
  }
}
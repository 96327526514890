.navbar {
  -webkit-font-smoothing: antialiased;
  background-color: #fff !important;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  width: 100%;
  z-index: 970;
  justify-content: start;
}

.navbar-light .navbar-toggler {
  color: #000 !important;
  border-color: transparent;
}

.bm-cross-button {
  height: 0px !important;
  width: 0px !important;
}
.bm-cross {
  width: 0px !important;
  height: 0px !important;
}
.absolute {
  position: absolute !important;
}
.nav-item-icon.active {
  color: rgba(0, 0, 0, 0.5);
}
.fix {
  position: fixed;
  top: 15px;
  z-index: 999;
  left: 55px;
}
.hover-bag:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
.floating_icon {
  position: absolute;
  top: 2px;
}
.new_search_margin {
  margin-top: 10px;
}

.nav_form {
  width: 100% !important;
  border-radius: 0rem;
}

.nav-item {
  color: black;
  text-decoration: none;
  letter-spacing: 1px;
}

.btn-outline-primary {
  color: black;
  border: 1px solid #e5e5e5;
  border-radius: 0rem;
}

.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

.btn-outline-primary:active {
  color: black !important;
  background-color: #fff !important;
  border: 1px solid #e5e5e5 !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

.btn-secondary {
  background-color: black;
  border-radius: 0rem;
}

.btn-secondary:active {
  background-color: black !important;
}

.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}
.new_nav_item {
  display: block;
  width: 100%;
}
.new_nav_item li {
  color: white;
}
.new_nav_item:hover li {
  color: rgba(255, 255, 255, 0.5);
}
.logo {
  width: 45px;
  margin-top: 0px;
  margin-left: 0px;
}
.visible {
  display: block !important;
}
.navbar-menu {
  align-items: center;
  margin-left: auto;
}
.search-content {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
}
.dropdown-divider {
  margin: 0;
}
.no-pd {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.remenber-search,
.search-img {
  height: 40px;
  margin-right: 5px;
}
.remenber-search {
  display: flex;
  align-items: center;
  background-color: #ffff99 !important;
}

.shop-dropdown-btn {
  color: black !important;
}
.shop-dropdown {
  background-color: black;
  min-width: 12rem;
  border: 0;
  border-radius: 0;
  padding: 20px 0;
}
.shop-dropdown a {
  color: white;
}
.shop-dropdown a:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: rgba(0, 0, 0, 0.5);
}
.shop-dropdown .dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(255, 255, 255, 0.8);
}
.menu-mobile {
  display: none;
  padding-top: 10px;
}
#navbarShopDropdown:hover {
  color: #000 !important;
}
.input-search {
  font-size: 0.8rem;
}

.home-tab:hover {
  background-color: rgb(248, 248, 248);
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .navbar-menu .nav-item,
  .btn-menu {
    font-size: 16px;
  }
}

.mobile-search {
  position: fixed;
  top: 52px;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: white;
  display: none;
}
.search-content-mobile {
  border: 0;
  border-radius: 0;
  top: 0;
  height: 85vh;
  width: 100%;
  overflow: scroll;
  position: relative;
  overflow-x: hidden;
}
.close-search {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.btn-search-glass {
  background-color: transparent;
  border: transparent;
  padding: 0;
}
.center_logo {
  margin: auto;
  text-align: center;
}

/*# sourceMappingURL=navbar.css.map */
@media (min-width: 576px) and (max-width: 767.98px) {
  .nav_btn_container {
    display: none;
  }
  .center_logo {
    text-align: left;
  }
}
@media (max-width: 575.98px) {
  .nav_btn_container {
    display: none;
  }
  .center_logo {
    text-align: left;
  }
}

/* Menu size */
.bm-menu {
  width: 480px;
}
.bm-menu-wrap {
  width: 480px !important;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  /* Menu size */
  .bm-menu {
    width: 315px;
  }
  .bm-menu-wrap {
    width: 315px !important;
  }
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 18px;
  height: 12px;
  left: 20px;
  top: 21px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 1px !important;
  width: 1px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 0em 0em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fff;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: #fff;
}

.special_li {
  width: 100%;
  height: 200px;
  text-align: center;
  font-size: 1.2rem;
  background-position: top !important;
  background-size: cover !important;
  letter-spacing: 4px;
}

.new_nav_item {
  color: white;
  font-size: 1.5rem;
}
.upperacase {
  text-transform: uppercase;
}
.center_li {
  padding: 5.1em;
}

.li_border {
  border-bottom: 0.5px solid #cfcfcf;
}

.nav_li_item {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  text-align: center;
  position: relative;
}
.nav_li_item i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.Menu-closit .bm-menu {
  overflow-x: hidden !important;
}

.special_btn {
  width: 50% !important;
}

.bm-cross-button {
  right: 20px !important;
  top: 6px !important;
}
.center_margin {
  height: 100%;
  align-items: center;
}
.center_top {
  height: 40px;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.center_top p {
  margin-bottom: 0;
}
.icons-menu {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  width: 80px;
  justify-content: flex-end;
}
.icons-menu .icon {
  padding-left: 10px;
  position: relative;
}

.nav-pad {
  padding-top: 8px;
  padding-bottom: 8px;
}
.btn-search-glass {
  cursor: pointer;
}
.bag-badge {
  background-color: #ffffff;
  border-radius: 100px;
  display: block;
  height: 13px;
  width: 13px;
  color: black;
  font-size: 0.6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1px;
  left: 3px;
}

.home-tab {
  background-color: #ffffff;
  padding-left: 10px;
  transition: 0.3s linear all;
}
.home-tab a:hover {
  color: #000;
}
.Menu-closit .bm-menu {
  background-color: #ffffff;
}
.input-container {
  position: relative;
  width: 100% !important;
}
.input-icon {
  position: absolute;
  right: 10px;
  top: 0;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.search-input:hover {
  border-color: #000;
}
.inputbg {
  background-color: transparent;
}
.search-input::placeholder {
  color: #959595 !important;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.navbar-separator {
  width: 98%;
  height: 1px;
  background-color: #e2e2e2;
}

.btn_border {
  border: 1px solid #e2e2e2;
  padding: 9px;
  border-radius: 6%;
  cursor: pointer;
  transition: 0.3s;
  transition: 0.3s linear all;
}
.btn_border:hover {
  border: 1px solid #000;
}

.btn_fill {
  background-color: #000;
  padding: 9px;
  border-radius: 6%;
  color: #fff !important;
  cursor: pointer;
  transition: 0.3s linear all;
}
.btn_fill:hover {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #000;
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  .logo {
    width: 45px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .icons-menu a {
    font-size: 0.9rem;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .logo {
    width: 45px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .icons-menu a {
    font-size: 0.95rem;
  }
}

@media only screen and (min-width: 992px) {
  .special_li {
    height: 240px;
  }
  .center_li {
    padding: 7rem;
  }
  .nav_li_item i {
    right: 7px;
  }
}

/*xs*/
@media (max-width: 575.98px) {
  .search-input {
    width: 100% !important;
  }
  .logo {
    width: 45px;
    margin-top: 0px;
    margin-left: 4px;
  }
}

.nav_search_input_container {
  list-style: none;
  flex-direction: row;
}
.special-sign{
  float: right;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

/*sm*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .search-input {
    width: 100% !important;
  }
  .nav_search_input_container {
    min-width: 30%;
  }
}

/*md*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-input {
    width: 100% !important;
  }
  .nav_search_input_container {
    min-width: 40%;
  }
}

/*lg*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-input {
    width: 100% !important;
  }
  .navbar-expand-lg .navbar-collapse {
    align-items: center !important;
    justify-content: start !important;
  }
  .nav_search_input_container {
    min-width: 25%;
  }
}

/*xl*/

@media (min-width: 1200px) {
  .search-input {
    width: 100% !important;
  }
  .home-tab:hover {
    background-color: #fff !important;
  }
  .home-tab a:hover {
    color: rgba(0, 0, 0, 0.5) !important;
    background-color: #fff !important;
  }
  .nav_search_input_container {
    min-width: 20%;
  }
  .nav_menu_container {
    max-width: 780px;
  }
}

@media (min-width: 1400px) {
  .nav_search_input_container {
    min-width: 42%;
  }
}

.forms-box {
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
  /* background-color: rgba(128, 128, 128, 0.5); */
  z-index: 9999;
  top: 0;
}
.slogan {
  text-align: center;
  font-size: 1rem;
}
.password-eye {
  position: absolute;
  top: 14px;
  right: 37px;
}
.box-form {
  position: relative;
  display: block;
  min-height: 100vh;
  overflow: auto;
}
.new-input {
  color: #000;
  border-color: rgb(229, 229, 229) !important;
  font-size: 0.8rem;
}
.new-input:focus {
  border-color: rgb(229, 229, 229) !important;
}
.logo-modal {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 140px;
  margin-bottom: 20px;
}
.centered {
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}
.login-bg {
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px -1px black;
  position: relative;
}
.underlined-b {
  text-decoration: underline black !important;
}
.box-form input {
  height: 50px;
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
}
.max-w-440 {
  max-width: 440px;
}
.forgot {
  text-align: right;
  font-size: 0.8rem;
}
.other-options {
  margin-top: 20px;
  font-size: 0.7rem;
  color: rgb(102, 102, 102);
}
.success-text {
  font-size: 12px;
  color: green;
}
.error-text {
  font-size: 12px;
  color: red;
}
.error-input {
  border-color: red;
}
.hidden {
  display: none;
}
.close_x {
  position: absolute;
  right: 25px;
  top: 25px;
  margin-bottom: 0;
  cursor: pointer;
}
.btn-size {
  font-size: 1rem;
  padding: 0.7rem;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .centered {
    padding-top: 60px;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-bg .grid figure {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  max-width: 400px;
  max-height: 400px;
  border-radius: 250px;
  width: 140px;
  height: 140px;
}
.input-new-image {
  height: 100% !important;
  z-index: 100;
}
.grid figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-new-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure p {
  margin: 0;
  letter-spacing: 1px;
  font-size: 68.5%;
}

figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 0.75rem;
  height: 4.5rem;
  background: #fff;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
}

figure.effect-zoe:hover figcaption {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

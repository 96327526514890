/*General Category*/
.Category-Details {
  width: 100%;
  background-color: #fff;
  position: relative;
}

.card-prod-card {
  width: 100% !important;
  height: 100%;
  border: 0;
}

.categories-details-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.description-text-category {
  text-transform: uppercase;
  color: #ffffff;
}

.top-card,
.bottom-card {
  padding: 10px 0;
  width: 100%;
}

.card-body-prod-card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.no-pd {
  padding-left: 0;
  padding-right: 0;
}
.bg-gray {
  background-color: rgba(221, 221, 221, 0.2);
}
.Category-Details.separator-section {
  margin: 0 0 10px 0;
}
.card-body-prod-card .bottom-card {
  padding: 0;
  width: 100%;
}
.card-body-prod-card .card-title,
.card-body-prod-card .card-text {
  text-transform: capitalize;
}
.separator-row {
  margin-bottom: 15px;
}
.card-body-prod-card .card-title {
  font-size: 1rem;
}
.card-body-prod-card .card-text {
  font-size: 0.8rem;
}
.card-body-prod-card .card-price {
  font-size: 0.8rem;
}
.empty-list {
  font-size: 1.5rem;
}
.banner-category-detail {
  /*EN PROD*/

  width: 100%;

  /*EN DEV*/
  /* width: 100%;
  object-fit: cover;
  object-position: top;
  height: 480px;*/
}
.loader-categories {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
}

.banner-category-detail.mobile {
  display: none;
}

.description-box-category {
  background-color: #000000;
  text-align: center;
  padding: 10px 0 0 0;
}

.Category-Details .wishlist-box,
.Categories-Page .wishlist-box {
  right: 20px;
}

.card-price .marked-price {
  font-size: 0.7rem;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .banner-category-detail.desk {
    display: none;
  }
  .banner-category-detail.mobile {
    display: block;
  }
}

/* LOT Detail */

.AuctionDetailPage {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 20px;
}

.lot_container {
  width: 95%;
  margin: auto;
  overflow: hidden;
}

.lot_banner {
  /*EN PROD*/
  width: 100%;

  /*EN DEV*/
  /*width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: top;*/
}

.auction_detail_title {
  font-size: 1.5rem;
  line-height: 1.2;
  text-transform: capitalize;
  color: #ffffff;
}

.auction_detail_separator {
  border-bottom: 0.3px solid #e5e5e5;
}

.auction_detail_date {
  font-size: 0.8rem;
  line-height: 1.4;
  margin: 0;
  color: #ffffff;
}

.auction_detail_description {
  font-size: 0.8rem;
  text-transform: capitalize;
}

.center {
  text-align: center;
}

.card_lot {
  border: 0px solid transparent;
}
.lot_date {
  font-size: 1rem;
}
.no-pd {
  padding-left: 0;
  padding-right: 0;
}
.card-body-lots {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.card-body-lots .card-title {
  text-transform: capitalize;
}
.top-card,
.bottom-card {
  padding: 10px 0;
  width: 100%;
}

.loader-auction {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* transparent countdown*/
.clockdiv-transparent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.clockdiv-transparent > div {
  padding: 15px 10px;
  display: inline-block;
  margin: 0 15px;
  width: 80px;
}
.clockdiv-transparent div:nth-child(1) {
  margin-left: 0;
}
.clockdiv-transparent div > span {
  display: inline-block;
  color: #fff;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  width: 100%;
}

.clockdiv-transparent .smalltext {
  padding-top: 5px;
  font-size: 16px;
  color: white;
  font-weight: 100;
  text-align: center;
}

@media (max-width: 575px) {
  .lot {
    width: 50%;
  }
}

.card-body-lots .card-title {
  font-size: 1rem;
  min-height: 60px;
}
.card-body-lots .card-text {
  font-size: 0.8rem;
}
.card-body-lots .card-text-price {
  font-size: 0.8rem;
  font-weight: bold;
}

.intro-box-auction {
  background-color: #000000;
  padding: 15px 0;
}
.intro-box-auction .auction_detail_title {
  text-align: center;
}
.intro-box-auction .auction_detail_date {
  text-align: center;
  position: relative;
}
.icon-auction-info {
  position: absolute;
  right: 30px;
}

.icon-auction-info:hover {
  color: white;
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .AuctionDetailPage .no-pd-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .clockdiv-transparent > div {
    margin: 0;
  }
  .intro-box-auction .auction_detail_title {
    text-align: center;
  }
  .intro-box-auction .auction_detail_date {
    text-align: center;
    position: relative;
  }
  .icon-auction-info {
    position: absolute;
    right: 30px;
  }
}
.image-box {
  position: relative;
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .banner-act {
    max-width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .lot-web {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .lot-mobile {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .lot-img-cover {
    /*EN PROD*/
    height: 240px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .lot-img-cover {
    /*EN PROD*/
    height: 380px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .lot-img-cover {
    /*EN PROD*/
    height: 340px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .lot-img-cover {
    /*EN PROD*/
    height: 260px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .lot-img-cover {
    /*EN PROD*/
    height: 265px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}

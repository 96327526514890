/* Similar Items */

.Similar {
  height: auto;
}

.similar_title_container {
  border-bottom: 0.1px solid #000000;
}

.img-lot-smilar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.NewAddress {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
}
.title-new-address {
  font-size: 1rem;
  text-transform: capitalize;
}
.location-form-input {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.btn-new-add-map {
  width: 100%;
  height: auto !important;
  padding: 0.7rem;
}
.location-form-input {
  padding: 1.5rem;
}
#completeManual .modal-input {
  margin-bottom: 1rem;
}
#completeManual .modal-title {
  padding-bottom: 0.5rem;
}

.location-form-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bcbcbc;
  text-align: center;
}

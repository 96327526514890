#form-btn {
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  width: 50px;
  height: 50px;
  bottom: 50px;
  right: 6%;
  border-radius: 50%;
  display: none;
  cursor: pointer;
}
#form-btn:hover {
  background-color: rgba(0, 0, 0, 1);
}
#form-btn div {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shadow-call {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 24px 0px;
}
#form-btn i {
  color: #ffffff;
  font-size: 26px;
}

.SizeGuide {
  height: auto;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.SizeGuide .table-box {
  overflow: auto;
}
.title-size {
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
}
.title-clothing {
  font-size: 1.2rem;
  line-height: 1.25;
}
.title-shoes {
  font-size: 1.2rem;
  line-height: 1.25;
}
.btn-size-back {
  width: 280px;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
  z-index: 9999;
  background-color: #000;
} */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #d3d3d3;
}
.table-striped tbody tr:nth-of-type(odd) th:first-child,
.table-striped tbody tr:nth-of-type(odd) td:first-child {
  background-color: #d3d3d3;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .table-box {
    position: relative;
    width: 100%;
    overflow-x: scroll;
  }
}

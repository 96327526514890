/* Configuration CSS */

.Account {
  height: auto;
  min-height: 100vh;
  padding-top: 0px;
  position: relative;
}
.account_loader {
  position: absolute;
  width: 100%;
  height: 64.4rem;
  top: -114px;
  left: 0;
  padding-top: 0px;
  background-color: rgba(239, 239, 239, 0.3);
  z-index: 9999;
}

.settings_title_container {
  background-color: #000;
}

.username_welcome {
  font-size: 1rem;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
}
.username {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.settings_title_container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.settings_title {
  font-size: 1rem;
  line-height: 1.25;

  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.dropdown-border {
  border-bottom: 0.5px solid #000000;
  margin-bottom: 20px;
}

.settings_description {
  text-align: left;
}

.settings-pd {
  padding: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.username_container {
  padding-top: 1rem;
}

.grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Settings .grid figure {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  max-width: 400px;
  max-height: 400px;
  border-radius: 250px;
  width: 240px;
  height: 240px;
}

.grid figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-new-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure p {
  margin: 0;
  letter-spacing: 1px;
  font-size: 68.5%;
}

figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 0.75rem;
  height: 4.5rem;
  background: #fff;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
}

figure.effect-zoe:hover figcaption {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.dropdowns_container {
  padding-top: 1rem;
}
.loader-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 52px;
  background-color: rgba(239, 239, 239, 0.3);
  z-index: 9999;
}
.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
@media (min-width: 1200px) {
  .settings-width {
    max-width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .settings-width.container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .header-settings {
    background-color: #000;
  }
  .username_container {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .Settings {
    padding-top: 59px;
  }
  .Settings .grid figure {
    border-radius: 250px;
    width: 220px !important;
    height: 220px !important;
  }
  .settings_title_container {
    padding-bottom: 1rem;
  }
}

.title-shop-item-card {
  letter-spacing: 0.1rem;
  font-size: 36px;
  line-height: 60px;
  color: #fff;
}
.text-shop-item-card {
  font-size: 16px;
  line-height: 30px;
  color: #fff;
}
.btn-shop-item-card {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}
.sidebar-overlay {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.2;
}

.carousel-shop-item-card .carousel-inner,
.carousel-shop-item-card .carousel-item {
  height: 100%;
}
.carousel-shop-item-card img {
  object-fit: cover;
  object-position: top;
  height: 100%;
}
.carousel-shop-item-card .carousel-caption a,
.carousel-shop-item-card .carousel-caption p,
.carousel-shop-item-card .carousel-caption h5 {
  cursor: pointer;
}
.carousel-shop-item-card .carousel-caption .btn-shop-item-card:hover {
  text-decoration: underline rgba(0, 0, 0, 0.5);
}

.carousel-shop-item-card .btn-shop-item-card a:hover {
  text-decoration: underline black;
}
.col-shop-item-card {
  margin-bottom: 15px;
  max-height: 465px;
  min-height: 465px;
}
.title-shop-item-card {
  font-size: 1.5rem;
  text-transform: uppercase;
}
.text-shop-item-card {
  font-size: 0.8rem;
}
.carousel-shop-item-card .caption-center {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.caption-tgt {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loader-shop {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}
@media only screen and (min-width: 768px) {
  .carousel-shop-item-card {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .carousel-shop-item-card {
    height: 300px;
  }
  .title-shop-item-card {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .title-shop-item-card {
    font-size: 1.4rem;
  }
}

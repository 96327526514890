.Addbook {
  height: auto;
  min-height: 100vh;
  padding-top: 58px;
  background-color: #efefef;
  position: relative;
}
.title-addbook {
  font-size: 1rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.name-product-addbook {
  font-size: 1rem;
  text-transform: capitalize;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.cards-addbook {
  box-shadow: 0 0 0px 0px black;
  border: 0;
  border-radius: 2px;
  margin-bottom: 20px;
}
.cards-addbook p {
  line-height: 1.2;
  font-size: 1rem;
}
.cards-addbook-body .switch {
  margin-left: auto;
  display: block;
  margin-bottom: 1rem;
}

.second-col-addbook {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.second-col-addbook .bottom-addbook {
  margin-top: auto;
}
.first-col-addbook {
  display: flex;
  flex-direction: column;
}
.first-col-addbook .bottom-addbook {
  margin-top: auto;
}
.bg-intro-addbook {
  background-color: white;
  margin-bottom: 10px;
}

.btn-new-addbook {
  margin-bottom: 1rem;
}

.trash-addbook {
  font-size: 1.5rem !important;
  margin-bottom: 0;
  cursor: pointer;
}
.name-addbook,
.add-addbook,
.prefered-addbook {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.small_padding {
  padding: 0.7rem;
}
.large_padding {
  padding: 2.5rem;
}
.new-col-pd {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.loader-book {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .cards-addbook-body {
    padding: 1rem 0;
  }
  .second-col-addbook {
    padding-right: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .second-col-addbook {
    padding-right: 0;
  }
  .first-col-addbook {
    padding-left: 0;
  }
}

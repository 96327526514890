/* Similar Items */

.Similar {
  height: auto;
}

.similar_title_container {
  border-bottom: 0.5px solid #000000;
}

.similar_title {
  font-size: 1.1rem;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.Similar .marked-price {
  font-size: 0.7rem;
}

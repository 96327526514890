/* Star CSS */

.Star {
  background-color: #000000;
  height: auto;
}

.rating_title {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1rem;
}

.rating_text {
  color: #ffffff;
  font-size: 1rem;
}

.rating-star {
  color: #ffffff;
  font-size: 2rem;
}

.star_btn {
  width: 25%;
}

.star-title_container {
  border-bottom: 0.5px solid #000;
}

.star-modal {
  background-color: #ffffff;
}

.x {
  position: absolute;
  top: 4px;
  left: 12px;
  font-size: 33px;
  z-index: 1;
  font-weight: 100;
}

.modal-header {
  border-bottom: 0px solid #e9ecef;
}
.modal-title {
  font-size: 1rem;
}
.modal-subtitle {
  font-size: 0.8rem;

  color: #495057;
}

.modal-body {
  border-top: 0px;
  border-bottom: 0px;
}

.modal-input {
  color: #000;
  border-color: #000 !important;
  font-size: 1.1rem;
  height: 3.1rem;
  background-color: #f7f7f7;
  border-radius: 0px;
}
.modal-input:focus {
  border-color: #000 !important;
  background-color: #f7f7f7;
}
.modal-footer {
  border-top: 0px;
}
.stars-file i {
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .star_btn {
    width: 90%;
  }
}

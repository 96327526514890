.intro-portrait {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 1rem;
}
.title-portrait {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5rem;
}
.text-portrait {
  font-size: 0.8rem;
}

/*General Category*/
.Category {
  width: 100%;
  background-color: #fff;
  position: relative;
  min-height: 410px;
}
.loader-categories-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loader-categories-area .loader-categories {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.category {
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  line-height: 1.33;
  color: #666;
  margin-bottom: 0;
}
.category_margin {
  margin-top: 20px;
}
.category_name {
  font-size: 1.1rem;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
  display: inline-block;
  text-transform: uppercase;
  width: 100%;
  position: relative;
}
.category-shop-all {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.8rem;
  display: block;
  cursor: pointer;
  text-transform: initial;
}
.card-category {
  border: 0;
}
.card-category-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.card-category-title {
  font-size: 1rem;
  /*height: 35px;*/
  margin-bottom: 0;
}

.card-category-text {
  font-size: 0.8rem;
  height: 2.5rem;
  overflow: hidden;
}
.card-category-price {
  font-size: 0.8rem;
}
.category-hr {
  width: 100%;
  border-bottom: 0.1px solid #eaeaea;
  margin-top: 0;
}

.loader-category {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card-category-price .marked-price {
  font-size: 0.7rem;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .col-shop-item-card {
    padding-left: 0;
    padding-right: 0;
  }
  .category-hr {
    width: 100%;
    border-bottom: 0.1px solid #eaeaea;
    margin-top: 0;
    position: absolute;
    z-index: 1;
    left: 0;
  }
}

/* Causes CSS */

.Causes {
  height: auto;
  background-color: #000000;
}
.causes_title_container {
  padding-top: 30px;
  padding-bottom: 30px;
}
.causes_title {
  text-transform: uppercase;
  color: #ffffff;
}

.causes_subtitle {
  color: #ffffff;
}

.OrderInformation {
  height: auto;
  min-height: 100vh;
  padding-top: 65px;
  background-color: #efefef;
}

.OrderInformation .marked-price{
  font-size: .7rem;
}

.mb-20{
  margin-bottom: 20px;
}

.title-order {
  font-size: 1rem;
  text-transform: capitalize;
}
.order-number-title {
  font-size: 1rem;
  text-transform: capitalize;

  margin-bottom: 0.5rem;
}
.order-card {
  box-shadow: 0 0 0px 0px black;
  border: 0;
  border-radius: 2px;
  margin-bottom: 20px;
}

.order-card.h-100 {
  margin-bottom: 0;
}

.order-card p {
  line-height: 1.2;
}
.title-order-final {
  font-size: 1rem;
}
.icon-check i {
  font-size: 2rem;
}
.OrderInformation .second-col * {
  text-align: right;
  margin-bottom: 0.5rem;
}
.OrderInformation .first-col * {
  text-align: left;
  margin-bottom: 0.5rem;
}
.order-text {
  font-size: 0.8rem;
}
.img-order-expanded {
  width: 100%;
  height: 300px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  display: block;
}
.label-discount {
  font-size: 1.2rem;
  color: #000;
  font-weight: 900;
}

.order-btn-box{
  margin: 0 0 20px 0;
}
.btn-order-info{
  max-width: 300px;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  display: block;
}
.btn-order-info a{
  color: white;
  transition: all 500ms ease;
}
.btn-order-info:hover a{
  color: black;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .order-card-body {
    padding: 1rem 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .OrderInformation .no-pd-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

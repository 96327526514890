/* Details and Password CSS */

.DetailAndPassword {
  height: auto;
  min-height: 100vh;
  padding-top: 52px;
}

.setting-title_container {
  border-bottom: 0.1px solid #000000;
}
.detail_title {
  font-size: 1.1rem;
}
.setting-title {
  margin-bottom: 10px;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.datepicker-insta {
  width: 100%;
}

.DayPickerInput input {
  color: #000;
  border: 1px solid;
  border-color: rgb(229, 229, 229) !important;
  font-size: 0.8rem;
  padding: 1.5rem;
  height: calc(2.25rem + 2px);
}

.grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid figure {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  max-width: 400px;
  max-height: 400px;
  border-radius: 250px;
  width: 260px;
  height: 260px;
}

.grid figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-new-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure p {
  margin: 0;
  letter-spacing: 1px;
  font-size: 68.5%;
}

figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 0.75rem;
  height: 4.5rem;
  background: #fff;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
}

figure.effect-zoe:hover img {
  /*opacity: 0.8;*/
}

figure.effect-zoe:hover figcaption {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.password-container p {
  font-size: 1.1rem;
  line-height: 1.25;
  text-align: center;
}

.loader-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 52px;
  background-color: rgba(239, 239, 239, 0.3);
  z-index: 9999;
}
.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

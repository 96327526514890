#carousel-closit-main {
  height: 540px;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0;
}

#carousel-closit-main .carousel-inner {
  height: 100%;
}
#carousel-closit-main .carousel-item {
  height: 100%;
}
#carousel-closit-main img {
  object-fit: cover;
  object-position: top;
  height: 100%;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
#carousel-closit-main .carousel-indicators {
  bottom: -40px;
}
#carousel-closit-main .carousel-indicators .active {
  background-color: black !important;
}
#carousel-closit-main .carousel-indicators li {
  background-color: rgba(128, 128, 128, 0.7);
}
#carousel-closit-main .carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}
.slider-title.undefined {
  position: absolute;
  width: 300px;
  height: 85px;
  top: 10px;
  left: 10px;
  color: #ffffff;
  z-index: 100;
  padding: 10px;
}

.slider-title.topl {
  position: absolute;
  width: 300px;
  height: 85px;
  top: 10px;
  left: 10px;
  color: #ffffff;
  z-index: 100;
  padding: 10px;
  text-align: left;
}
.slider-title.topr {
  position: absolute;
  width: 300px;
  height: 85px;
  top: 10px;
  right: 10px;
  color: #ffffff;
  z-index: 100;
  padding: 10px;
  text-align: right;
}
.slider-title.botl {
  position: absolute;
  width: 300px;
  height: 85px;
  bottom: 10px;
  left: 10px;
  color: #ffffff;
  z-index: 100;
  padding: 10px;
  text-align: left;
}
.slider-title.botr {
  position: absolute;
  width: 300px;
  height: 85px;
  bottom: 10px;
  right: 10px;
  color: #ffffff;
  z-index: 100;
  padding: 10px;
  text-align: right;
}

.slider-title h1 {
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
}
.img-desk {
  display: block;
}
.img-mobile {
  display: none;
}

.loader-slider-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loader-slider {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  #carousel-closit-main {
    margin-top: 0;
    height: 360px;
  }
  .slider-title h1 {
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
  }
  .img-desk {
    display: none;
  }
  .img-mobile {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  #carousel-closit-main {
    max-width: 100%;
  }
}

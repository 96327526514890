/*Journal*/

.Journal-detail {
  height: auto;
  min-height: 100vh;
  padding-top: 59px;
}
.journal-detail-title {
  font-size: 1rem;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
.journal-detail-banner {
  /*EN PROD*/
  /*width: 100%;*/

  /*EN DEV*/
  width: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 430px;
}
.body-journal-detail {
  padding: 30px 0;
}
.body-journal-detail img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.body-journal-detail * {
  font-size: 1rem;
}
.loader-journal-detail {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .journal-detail-banner.web {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .journal-detail-banner.mobile {
    display: none;
  }
}

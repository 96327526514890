.Cards {
  height: auto;
  min-height: 100vh;
  padding-top: 58px;
  background-color: #efefef;
  position: relative;
}
.cards-auctions {
  position: absolute;
  bottom: 20%;
  margin-bottom: 0 !important;
}
.title-cards {
  font-size: 1rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.name-product-cards {
  font-size: 1rem;
  text-transform: capitalize;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.cards-card {
  border: 0;
  border-radius: 2px;
  margin-bottom: 20px;
}
.cards-card p {
  line-height: 1.2;
}
.cards-card-body .switch {
  margin-left: auto;
  display: block;
  margin-bottom: 1rem;
}

.second-col-delivery {
  display: flex;
  flex-direction: column;
}
.second-col-delivery .bottom-delivery {
  margin-top: auto;
}
.first-col-delivery {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.first-col-delivery .bottom-delivery {
  margin-top: auto;
}
.bg-intro-cards {
  background-color: white;
  margin-bottom: 10px;
}

.btn-new-cards {
  margin-bottom: 1rem;
}
.trash-cards {
  text-align: right;
  font-size: 1.5rem;
  margin-bottom: 0;
}
.name-card,
.year-card,
.prefered-card {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.second-col-delivery,
.first-col-delivery {
  min-height: 120px;
}
.btn-new-cards.auctions {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.loader-cards {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 52px;
}
.card.cards-card:last-child {
  margin-bottom: 120px;
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .cards-card-body {
    padding: 1rem 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .Cards {
    padding-top: 60px;
  }
}

/* Configuration CSS */

.Settings {
  height: auto;
  min-height: 100vh;
  padding-top: 64px;
  position: relative;
}

.setting_nav {
  text-transform: capitalize;
}
